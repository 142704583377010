<template>
    <div>
    <div class="columns">
        <div class="column is-flex is-flex-direction-column pb-0">
        <div class="level p-0 m-0">
            <div class="level-left">
            <div class="level-item">Furnitore</div>
            <input class="input" style="margin: 5px; width: 300px" 
                type="text" placeholder="Kerko..." 
                v-model="global_search_filter" @input="onSearchEntity">
            </div>
            <div class="level-right">
            <div class="level">
                <a @click.prevent="create_supplier">
                <i class="fa fa-plus"></i>
                </a>
            </div>
            </div>
        </div>
        <ag-grid-vue
            style="width: 100%; min-height: 40vh; height:80vh;"
            class="ag-theme-balham is-flex-grow-1"
            :columnDefs="columnDefs"
            :rowData="rowData.value"
            :rowSelection="rowSelection"
            :context="context"
            :animateRows="true"
            :localeText="localeText"
            @grid-ready="onGridReady"
        >
        </ag-grid-vue>
        </div>
        <div class="column is-5" v-if="form_edit || form_create">
        <DaForm v-if="form_create" @submitted="submitSupplier" @close="form_create = null" :closes_form="true"
          :form="form_create">
        </DaForm>
        <DaForm v-if="form_edit" @submitted="submitSupplier" @close="form_edit = null" :closes_form="true"
          :form="form_edit">
        </DaForm>
        </div>
    </div>
    </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AgGridVue } from "ag-grid-vue3";
import DaForm from "@/components/daform/index.vue";
import ButtonsCell from "@/components/buttonscell/ButtonsCell.vue";
import locale from "@/components/ag-grid/locale/al/al.js"

import createSupplier from "./forms/create";
import editSuplier from "./forms/edit";
import SupplierServices from "@/services/Supplier";

import socket from '@/socket.js';
import { onUnmounted, reactive, inject } from 'vue'
export default {

    components: {
        AgGridVue,
        DaForm,
    },
    setup(){
        let rowData = reactive([])
        const toast = inject('toast')
        socket.connect()
        socket.on("welcome", data => {
        console.log("welcome", data)
        })
        socket.on('propagate', data => {
        if(data.entity == 'suppliers') {
            if(data.action == 'create') {
            var a = rowData.value
            a.push(data.data)
            rowData.value = [...a]
            toast.success('Furnitori u krijua <i>nga ' + data.created_by.name + '</i>')
            }
            if(data.action == 'edit') {
            rowData.value = [...rowData.value.map(item => {
                if(item.id == data.data.id){
                return data.data
                }
                return item
            })]
            toast.success('Furnitori u modifikua <i>nga ' + data.created_by.name +'</i>')
            }
        }
        })
        onUnmounted(() => {
            socket.off('welcome')
            socket.off('propagate')
            socket.disconnect()
            })
        return {
            rowData
        }
    },
    beforeMount() {
        this.context = { componentParent: this }
    },
    async created() {
        // if(!this.am_allowed('supplier.can_see_suppliers')){
        // window.location.href = '/home'
        // }
        this.localeText = locale.locale_al()
        this.rowData.value = await SupplierServices.getSuppliers();
        this.$root.setCurrentRoute("furnitore-lista");
        this.gridApi.sizeColumnsToFit();
    },
    data() {
        return {
            global_search_filter : "",
            context              : null,
            selectedSupplier     : {},
            form_create          : null,
            form_edit            : null,
            columnDefs: [
                { headerName: "Kategoria", field: "type_id_name", filter: true,
                cellRenderer: function(params) {
                let r = ""
                if(params.data.type_id == "EMPTY-STRING")
                    r = `<i class="type_id">[SKA KATEGORI]</i>`
                else
                    r = params.data.type_id_name
                return r
                },
                floatingFilter: true, sortable: true, },
                { headerName: "Kodi", field: "code", filter: true, floatingFilter: true, sortable: true, resizable: true},
                { headerName: "Emri", field: "name", filter: true, floatingFilter: true, sortable: true, resizable: true},
                { headerName: "Action", cellRenderer: ButtonsCell, cellRendererParams: this.context, resizable: true},

            ],
            gridApi   : null,
            columnApi : null,
            defaultColDef: {
                flex: 1,
                minWidth: 100,
            },
            rowSelection        : "single",
            localeText          : null,
            show_tab: 1,
        };
    },
    methods: {
        onGridReady(params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
        this.gridColumnApi = params.columnApi;
        },
        onSearchEntity() {
            this.gridApi.setQuickFilter(this.global_search_filter)
        },
        async open(mode, supplier) {
            if(mode == '1'){ 
                // edit mode
                /* creates a form for edit of the supplier                      */
                this.form_create = null;
                if (supplier) {
        
                    /* create edit form for the supplier                        */
                    setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
                    this.form_edit = {loading:true}
                    this.form_edit = await editSuplier(supplier);
                    setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);

        
                    this.selectedSupplier = supplier;
                }
            }
        },
        async create_supplier() {
            this.form_edit = null;
            setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
            this.form_create = {loading: true}
            this.form_create = await createSupplier()
            setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
        },
        async submitSupplier(f) {
            var notify_text = ""
            if(this.form_create != null){
                let supplier = await SupplierServices.createSuppliers(f)
                notify_text = "Furnitori u krijua me sukses"
                var a = this.rowData.value
                a.push(supplier)
                this.rowData.value = [...a]
                socket.emit("propagate", {entity: "suppliers", action: "create", data: supplier})
                this.form_create = null
                this.$root.selected_one = {}
            }
            else {
                f.guid = this.selectedSupplier.guid
                let supplier = await SupplierServices.editSuppliers(f)
                this.rowData.value = [...this.rowData.value.map(e => {
                    if(e.guid == supplier.guid) e = { ...supplier }
                    return e
                })]
                notify_text = "Furnitori u modifikua me sukses!"
                socket.emit("propagate", {entity: "suppliers", action: "edit", data: supplier})
                this.form_edit = null
                this.$root.selected_one = {}
            }
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit()
            }, 100);
            this.$toast.success(notify_text)
        }
    },
}
</script>

<style>

</style>
