import CategoriesServices from "@/services/Category";
import helpers from "@/helpers.js";

export default async function createSupplier(){

  var supplier_categories = helpers.toTree(await CategoriesServices.getCategories("suppliers"), "id") 

  return {
    name: "Krijo Furnitore",
    submit: {
      label: "Krijo",
      action: "submitSupplier",
    },
    fields: [
      [
        {
          value: "",
          name: "code",
          label: "Kodi",
          type: "text-field",
          style: "min-width: 30%",
          required: true,
          required_text: "Kjo fushe eshte e detyrueshme",
          unique: {
            model: "supplier",
            field: "code",
            where: [],
          },
          left_icon: "fa-solid fa-hashtag",
        },
        {
          value: "",
          name: "name",
          label: "Emri",
          type: "text-field",
          style: "min-width: 70%",
          required: true,
          required_text: "Kjo fushe eshte e detyrueshme",
        },
      ],
      [
        {
          name: 'type_id',
          label: 'Kategoria',
          type: 'tree-select',
          style: 'min-width: 100%',
          options: supplier_categories,
          selected_options: [],
          mode: 'single'
        }
      ]
    ],
  };
}
