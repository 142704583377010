import CategoriesServices from "@/services/Category";
import helpers from "@/helpers.js";

export default async function editSupplier(s){
    var supplier_categories = helpers.toTree(await CategoriesServices.getCategories("suppliers"), "id") 

    var suppliers_selected_categories = {}
    suppliers_selected_categories[s.type_id] = s.type_id_name

    return {
        name: "Modifiko furnitore",
        submit: {
            label: "Modifiko furnitore",
            action: 'submitSupplier'
        },
        fields: [

            [{
                value: s.code,
                name: 'code',
                label: 'Kodi',
                type: 'text-field',
                required: true,
                required_text: 'Kjo fushe eshte e detyrueshme',
                style: 'min-width: 30%',
                unique: {
                    model: 'supplier',
                    field: 'code',
                    where: [
                        {
                        column: 'guid',
                        condition: '!=',
                        value: s.guid
                    }
                    ]
                },
                left_icon: 'fa-solid fa-hashtag'
            },
            {
                value: s.name,
                name: 'name',
                label: 'Emri',
                type: 'text-field',
                style: 'min-width: 70%',
                required: true,
                required_text: 'Kjo fushe eshte e detyrueshme'
            }],
            [
                {
                    name: 'type_id',
                    label: 'Kategoria',
                    type: 'tree-select',
                    style: 'min-width: 100%',
                    mode: 'single',
                    options: supplier_categories,
                    selected_options: suppliers_selected_categories
                }
            ]
        ]
    }
}
